/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.sk-fading-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #4cb050;
  border-radius: 100%;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  animation-delay: -0.1s;
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
#splash-screen {
  opacity: 1;
  transition: opacity 0.2s;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}